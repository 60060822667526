import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ApexCharts from 'apexcharts';

dayjs.extend(utc);
dayjs.extend(timezone);

function processDataForChart(data) {
  const series = [];
  let categories = new Set();

  Object.values(data).forEach(dataPoints => {
    Object.keys(dataPoints).forEach(duration => {
      categories.add(duration);
    });
  });

  categories = Array.from(categories).sort((a, b) => parseFloat(a) - parseFloat(b));

  Object.entries(data).forEach(([chartType, dataPoints]) => {
    const dataSeries = categories.map(duration => dataPoints[duration] || 0);
    series.push({ name: chartType, data: dataSeries });
  });

  return { series, categories };
}

function initCharts(chartData) {
  const processedData = processDataForChart(chartData);

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      zoom: { enabled: true }
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    series: processedData.series,
    xaxis: {
      categories: processedData.categories,
      title: { text: 'Number of Runs' }
    },
    yaxis: {
      title: { text: 'Execution Time (seconds)' },
      labels: { formatter: val => parseFloat(val).toFixed(2) }
    },
    fill: {
      opacity: 1
    }
  };

  const chartContainer = document.querySelector('.chart-container');
  if (chartContainer) {
    const chart = new ApexCharts(chartContainer, options);
    chart.render();
  }
}

pageLoad('algo_runs_index', () => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startedAtElements = document.querySelectorAll('.started-at');

  startedAtElements.forEach(element => {
    const startedAt = element.textContent;
    const localTime = dayjs(startedAt).tz(userTimezone);
    element.textContent = localTime.format('M/D/YY HH:mm');
  });

  const chartDataInput = document.getElementById('chart-data');

  if (chartDataInput) {
    const chartData = JSON.parse(chartDataInput.value);
    initCharts(chartData);
  }
});
